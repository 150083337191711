
import React from 'react';
import { Route } from 'react-router-dom';
import Register from './Authentication/Register';
import { ForgotPassword } from './Authentication/ForgotPassword';
import { ResetPassword } from './Authentication/ResetPassword';
import { CollectivityChild } from './CollectivityChild'
import { Account } from './Account/Account'
import { Space } from './Space'
import {Documents} from './Documents'
import { Dashboard } from './Dashboard';
import { Trainings } from './Training';
import { TrainingDocument } from './TrainingDocument'
import { AlertPreview } from './AlertPreview';
import { DashboardAlert } from './Dashboard/DashboardAlert';
import { LoadingPage } from './Loading';
import { ProviderCallback } from './Authentication/ProviderCallback'
import { AlertEventCreate } from '../Resources/AlertEvent/AlertEventCreate'

import {TestMap} from './Map';

import ViewModuleIcon from '@material-ui/icons/ViewModule';
import { ListBilan } from './Bilan';
import { PageContact } from './Contact';
import { TrainingFAQs } from './TrainingFAQs';
import { Services, Service } from './Service';

const router = [
    <Route exact path="/map"                component={TestMap} />,
    <Route exact path="/account"            component={Account} />,
    <Route exact path="/space"              component={Space} />,
    <Route exact path="/documents"          component={Documents} icon={ViewModuleIcon}/>,
    <Route exact path="/bbilans"            component={ListBilan} icon={ViewModuleIcon}/>,
    <Route exact path="/dashboard"          component={Dashboard} />,
    <Route exact path="/contact"            component={PageContact} />,
    <Route exact path="/services"           component={Services} />,
    <Route exact path="/services/:id"       component={Service} />,
    <Route exact path="/trainingDocuments"  component={TrainingDocument} />,
    <Route exact path="/trainingFaqs"       component={TrainingFAQs} />,
    <Route exact path="/carte"              component={DashboardAlert} />,
    <Route exact path="/collectivity-child" component={CollectivityChild} />,
    <Route exact path="/loading"            component={LoadingPage} noLayout />,

    <Route path="/alertPreview/:alertid"             component={AlertPreview}     noLayout />,
    <Route exact path="/register"                    component={Register}         noLayout />,
    <Route exact path="/provider/:provider/callback" component={ProviderCallback} noLayout />,
    <Route exact path="/forgotPassword"              component={ForgotPassword}   noLayout />,
    <Route       path="/resetPassword/:code"         component={ResetPassword}    noLayout />,

    <Route exact path="/training"                                   render={()=><Trainings action="home" />} />,
    <Route exact path="/training/:trainingID"                       render={()=><Trainings action="training" />} />,
    <Route exact path="/training/:trainingID/faq"                   render={()=><Trainings action="trainingfaq" />} />,
    <Route exact path="/training/:trainingID/:moduleID"             render={()=><Trainings action="module" />} />,
    <Route exact path="/training/:trainingID/:moduleID/:chapterID"  render={()=><Trainings action="chapter" />} />,

    // <Route exact path="/kit-de-communication"                       render={()=><Trainings action="home" />} />,
    <Route exact path="/kit-de-communication"                       render={()=><Trainings action="training" type="kdc" />} />,
    <Route exact path="/kit-de-communication/faq"                   render={()=><Trainings action="trainingfaq" type="kdc"/>} />,
    <Route exact path="/kit-de-communication/:moduleID"             render={()=><Trainings action="module" />} type="kdc"/>,
    <Route exact path="/kit-de-communication/:moduleID/:chapterID"  render={()=><Trainings action="chapter" />} type="kdc"/>,

    <Route exact path="/aide-documentaire"                       render={()=><Trainings action="training" type="aide-documentaire" />} />,
    <Route exact path="/aide-documentaire/faq"                   render={()=><Trainings action="trainingfaq" type="aide-documentaire"/>} />,
    <Route exact path="/aide-documentaire/:moduleID"             render={()=><Trainings action="module" />} type="aide-documentaire"/>,
    <Route exact path="/aide-documentaire/:moduleID/:chapterID"  render={()=><Trainings action="chapter" />} type="aide-documentaire"/>,

    <Route path="/alertEventCreate/:alertid"        component={AlertEventCreate} />,
    // <Route exact path="/alertEventCreate/:alertid/:tab"   component={AlertEventCreate} />,
];

export default router;