
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(1),
  }
}));


const generateRandomPerson = () => {
  const names = ['John', 'Jane', 'Alice', 'Bob', 'Charlie', 'Diana', 'Eve', 'Frank', 'Grace', 'Hannah'];
  const surnames = ['Doe', 'Smith', 'Johnson', 'Brown', 'Williams', 'Jones', 'Miller', 'Davis', 'Garcia', 'Rodriguez'];

  const randomName = names[Math.floor(Math.random() * names.length)];
  const randomSurname = surnames[Math.floor(Math.random() * surnames.length)];

  return {
    name: randomName,
    surname: randomSurname,
    checked: false
  };
};

  const dataArray = [
    {
      name: 'René',
      surname: 'UGO'
    },
    {
      name: 'René',
      surname: 'BOUCHARD'
    },
    {
      name: 'François',
      surname: 'CAVALLIER'
    },
    {
      name: 'Brigitte',
      surname: 'CAUVY'
    },
    {
      name: 'Jérôme',
      surname: 'SAILLET'
    },
    {
      name: 'Philippe',
      surname: 'DURAND-TERRASSON'
    },
    {
      name: 'Myriam',
      surname: 'ROBBE'
    },
    {
      name: 'Michel',
      surname: 'RAYNAUD'
    }
  ]

  const dataArrayCommune = [
    {
      name: 'Monsieur le Maire'
    },
    {
      name: 'Police Municipale'
    },
    {
      name: 'Service technique'
    },
    {
      name: 'Communication',
    }
  ]

export const FormProcedureInterne = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const handleChange = (event) => {
    console.log(event.target.name);
    console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { gilad, jason, antoine } = state;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

  return (<>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>Communautés de communes</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Sélectionnez les destinataires : </FormLabel>
          <FormGroup>
            {dataArray.map((item, key) =>        <FormControlLabel
              control={<Checkbox checked={state['user1_'+key]} onChange={handleChange} name={"user1_"+key} />}
              label={item.name+" "+item.surname}
            />)}
          </FormGroup>
        {/* <FormHelperText>Be careful</FormHelperText> */}
        </FormControl>
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>Communes</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Envoyez à </FormLabel>
            <FormGroup>
              {dataArrayCommune.map((item, key) =>        <FormControlLabel
                control={<Checkbox checked={state['user2_'+key]} onChange={handleChange} name={"user2_"+key} />}
                label={item.name}
              />)}
            </FormGroup>
          {/* <FormHelperText>Be careful</FormHelperText> */}
        </FormControl>
      </AccordionDetails>
    </Accordion>
  </>);
 }